const routes = {
  path: "/financiero",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "competencia",
			props: true,
      component: () => import("./views/Index.vue"),
      meta: { requiresAuth: true },
    }
  ],
};

export default routes;
